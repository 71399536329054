<template>
  <el-dialog class="loginDialog" :visible="recoverShow" @close="closeDialog('all')" :show-close="false">
    <div class="loginContent">
      <div class="loginLeft"></div>
      <div class="loginRight">
        <div class="loginTitle">
          <div class="titles">密码恢复</div>
          <img src="@/assets/home/close.svg" @click="closeDialog('all')" />
        </div>

        <div class="steps">
          <div class="strips" :class="{ activestrips: tabs > 0 }"></div>
          <div class="stripsTwo" :class="{ activestrips: tabs > 1 }"></div>
          <div class="stepDemo" :class="{ selectStep: tabs >= 0 }">
            <div class="setpTop">1</div>
            <div class="setpTitle">填写信息</div>
          </div>
          <div class="stepDemo" :class="{ selectStep: tabs >= 1 }">
            <div class="setpTop">2</div>
            <div class="setpTitle">接收邮件</div>
          </div>
          <div class="stepDemo" :class="{ selectStep: tabs >= 2 }">
            <div class="setpTop">3</div>
            <div class="setpTitle">恢复密码</div>
          </div>
        </div>
        <div class="" v-show="tabs == 0">
          <div class="FillinTitle">填写信息</div>
          <div class="FillinTips">
            您必须已经绑定电子邮件才能使用此功能。如果您还没绑定电子邮件，请联络您的
          </div>
          <div class="FillinTips" style="margin-top: 0">
            上线重设密码以启动此功能。
          </div>
          <div class="intDemo">
            <img class="leftIcon" src="@/assets/login/usernameIcon.svg" />
            <el-input
              class="usernameInt"
              v-model="username"
              placeholder="账号"
            ></el-input>
          </div>
          <div class="intDemo">
            <img class="leftIcon" src="@/assets/setup/emailIcon.svg" />
            <el-input
              class="usernameInt"
              v-model="email"
              placeholder="电子邮箱地址"
            ></el-input>
          </div>
          <div class="btnOut">
            <van-button class="btn register" @click="closeDialog('loginShow')"
              >返回</van-button
            >
            <van-button
              class="btn"
              :disabled="
                !reEmail.test(email) ||
                !/^[a-zA-Z0-9]+$/.test(username) ||
                codeLoading
              "
              :loading="codeLoading"
              @click="submit"
              >下一步</van-button
            >
          </div>
        </div>
        <div v-show="tabs == 1">
          <div class="FillinTitle">检查您的邮件</div>
          <div class="FillinTips">
            我们刚刚给您发送了一封邮件，请填写邮件中的验证码。
          </div>
          <div class="intDemo">
            <img class="leftIcon" src="@/assets/setup/emailIcon.svg" />
            <el-input
              class="usernameInt"
              disabled
              v-model="email"
              placeholder="电子邮箱地址"
            ></el-input>
          </div>
          <div class="intDemo">
            <img class="leftIcon" src="@/assets/setup/shield.svg" />
            <el-input
              class="usernameInt"
              v-model="verificationCode"
              placeholder="验证码"
            ></el-input>
          </div>
          <div class="btnOut tab2Btn">
            <van-button class="btn register" @click="tabs = 0">返回</van-button>
            <van-button
              class="btn"
              :disabled="verificationCode.length <= 0"
              @click="submit"
              >下一步</van-button
            >
          </div>
        </div>
        <div v-show="tabs == 2">
          <div class="FillinTitle">注意安全</div>
          <div class="FillinTips">为了安全起见，建议您每30天改变一次密码。</div>
          <div class="intDemo">
            <div class="pwdIcon">
              <img class="leftIcon" src="@/assets/login/porswordIcon.svg" />
            </div>
            <el-input
              class="usernameInt"
              :type="isPwd ? 'password' : 'text'"
              v-model="pwd"
              placeholder="新密码 (8-16位字母加数字组合)"
            ></el-input>
            <img
              class="eyes"
              src="@/assets/personal/closeEye.svg"
              v-show="isPwd"
              @click="isPwd = !isPwd"
            />
            <img
              class="eyes"
              src="@/assets/personal/openEye.svg"
              v-show="!isPwd"
              @click="isPwd = !isPwd"
            />
          </div>
          <div class="intDemo">
            <div class="pwdIcon">
              <img class="leftIcon" src="@/assets/login/porswordIcon.svg" />
            </div>
            <el-input
              class="usernameInt"
              :type="isPwd ? 'password' : 'text'"
              v-model="confirm_password"
              placeholder="确认密码"
            ></el-input>
            <!-- <img class="eyes" src="@/assets/personal/closeEye.svg" v-show="isPwd" @click="isPwd = !isPwd" />
                        <img class="eyes" src="@/assets/personal/openEye.svg" v-show="!isPwd" @click="isPwd = !isPwd" /> -->
          </div>

          <div class="btnOut tab2Btn">
            <van-button class="btn register" @click="tabs = 1">返回</van-button>
            <van-button
              class="btn"
              :loading="subLoading"
              :disabled="
                confirm_password.length <= 0 || pwd.length <= 0 || subLoading
              "
              @click="submit"
              >修改密码</van-button
            >
          </div>
        </div>

        <!-- :loading="subLoading" :disabled="subLoading" -->

        <!-- <div class="intDemo">
                    <div class="pwdIcon">
                        <img class="leftIcon " src="@/assets/login/porswordIcon.svg" />
                    </div>
                    <el-input class="usernameInt" v-model="pwd" :type="isPwd ? 'text' : 'password'"
                        placeholder="密码" clearable></el-input>
                    <img class="eyes" src="@/assets/personal/closeEye.svg" v-show="isPwd" @click="isPwd = !isPwd" />
                    <img class="eyes" src="@/assets/personal/openEye.svg" v-show="!isPwd" @click="isPwd = !isPwd" />
                </div> -->

        <!-- <div class="dibu">
                    <div class="titles">我们推荐使用以下浏览器以获得最佳使用体验</div>
                    <div class="imgDemo">
                        <img src="@/assets/login/google.svg" @click="goGoogle(0)" />
                        <img src="@/assets/login/safari.svg" @click="goGoogle(1)" />
                        <img src="@/assets/login/frame.svg" @click="goGoogle(2)" />
                    </div>
                </div> -->
        <div class="encounter">
          遇到问题？联系<img src="@/assets/login/service.svg"   @click="getService()" /><span   @click="getService()"
            >在线客服</span
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { sysSend, syssettingPassword } from "@/request/api";
export default {
  name: "recoverPop",
  props: {
    show: Boolean,
  },
  data() {
    return {
      isPwd: true,
      username: "",
      pwd: "",
      subLoading: false,
      setUser: false,
      confirm_password: "",
      tabs: 0,
      email: "",
      verificationCode: "",
      error: "",
      countdown: 0,
      countdownTimer: null,
      codeLoading: false,
      reEmail: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      getCodeLoading: false,
    };
  },
  computed: {
    ...mapState({
      recoverShow: (state) => state.popShow.recoverShow,
    }),
  },
  methods: {
    ...mapActions(["setActivePop"]),
    closeDialog(fieldName) {
      this.setActivePop(fieldName);
    },
    getService(){
                this.$store.commit("goService")
            },
    submit() {
      switch (this.tabs) {
        case 0:
          if (!/^[a-zA-Z0-9]+$/.test(this.username)) {
            this.$message.error("用户名只能包含字母和数字");
            return;
          }
          if (!this.reEmail.test(this.email)) {
            this.$message.error("请输入正确的邮箱地址");
            return;
          }
          this.getCodeBtn();
          // this.tabs++;
          break;
        case 1:
          this.tabs = 2;
          break;
        case 2:
          this.verifyPwd();
          //this.$router.push('/login')
          break;
        case 3:
          break;
      }
    },
    verifyPwd() {
      var regPwd = /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z]{6,18}$/;
      if (this.confirm_password != this.pwd) {
        this.$message.error("密码和确认密码不一致");
        return;
      }
      if (!regPwd.test(this.pwd)) {
        this.$message.error("密码为6-18位且包含字母和数字");
        return;
      }
      if (!this.verificationCode) {
        this.$message.error("请输入验证码");
        return;
      }
      this.subLoading = true;
      var pot = {
        username: this.username,
        code: this.verificationCode,
        password: this.pwd,
        email: this.email,
      };
      syssettingPassword(pot).then((res) => {
        if (res.code == 0) {
          // this.tabs++;
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.closeDialog("loginShow");
        }
        this.subLoading = false;
      });
    },
    getCodeBtn() {
      this.codeLoading = true;
      var pot = {
        username: this.username,
        email: this.email,
      };
      sysSend(pot).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "发送成功",
            type: "success",
          });
          this.tabs = 1;
          // this.startCountdown();
        } else if (res.code == 10007) {
          this.tabs = 0;
        }
        this.codeLoading = false;
      });
    },
    startCountdown() {
      this.countdown = 60; // 设置倒计时初始值为60秒
      this.countdownTimer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--; // 每秒减一
        } else {
          clearInterval(this.countdownTimer); // 当倒计时结束，清除定时器
        }
      }, 1000);
    },

    upLoginShow(newValue) {
      this.$emit("update:show", newValue);
    },
    goGoogle(index) {
      switch (index) {
        case 0:
          window.open("https://www.google.com/chrome/", "_blank");
          break;
        case 1:
          window.open("https://support.apple.com/zh-cn/docs", "_blank");
          break;
        case 2:
          window.open("https://www.mozilla.org/en-US/", "_blank");
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.loginDialog {
  /deep/.el-dialog {
    border-radius: 10px;
    width: 900px;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      border-radius: 10px;
      padding: 0;
    }
  }

  .loginContent {
    border-radius: 10px;
    display: flex;
    align-items: center;

    .steps {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: 62.745%;
      margin: 14px auto 0 auto;
      transition: all 0.5s ease;
      .strips {
        width: 30%;
        height: 3px;
        background: rgba(255, 255, 255, 0.04);
        position: absolute;
        top: 15px;
        left: 48px;
      }

      .stripsTwo {
        width: 30%;
        height: 3px;
        background: rgba(255, 255, 255, 0.04);
        position: absolute;
        top: 15px;
        right: 48px;
      }

      .activestrips {
        background: #0385ff;
      }

      .stepDemo {
        .setpTop {
          display: flex;
          width: 32px;
          height: 32px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 33px;
          background: rgba(255, 255, 255, 0.09);
          color: #fff;
          text-align: center;
          font-family: "SF Pro Display";
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          margin: 0 auto;
        }

        .setpTitle {
          color: rgba(255, 255, 255, 0.45);
          text-align: center;
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          margin-top: 10px;
        }
      }

      .selectStep {
        .setpTop {
          border-radius: 33px;
          background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);
        }

        .setpTitle {
          color: #fff;
        }
      }
    }

    .loginLeft {
      border-radius: 10px 0 0 10px;
      height: 577px;
      align-self: stretch;
      background: #D9D9D9 url('@/assets/login/leftImg.webp') no-repeat;
            background-size: 100% 100%;
      width: 350px;
    }

    .loginRight {
      width: 550px;
      padding: 20px;
      height: 577px;
      border-radius: 0 8px 8px 0;
      background: radial-gradient(
        133.65% 137.5% at -19.87% -5.42%,
        #02568e 6%,
        #13293b 61.18%,
        #152b3f 100%
      );
      position: relative;

      .loginTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .titles {
          color: #fff;
          font-family: "PingFang SC";
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
        }

        img {
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }

      .FillinTitle {
        color: #fff;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        margin-top: 28px;
      }

      .FillinTips {
        color: rgba(255, 255, 255, 0.65);
        text-align: center;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        margin-top: 14px;
        word-wrap: break-word;
      }

      .intDemo {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.04);
        background: rgba(255, 255, 255, 0.04);
        padding: 12px;
        margin-top: 22px;

        .leftIcon {
          width: 32px;
          height: 32px;
          margin-right: 10px;
        }

        .pwdIcon {
          margin-right: 5px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          .leftIcon {
            margin-right: 0;
            width: 20.4px;
            height: 24px;
            flex-shrink: 0;
          }
        }

        .usernameInt {
          background: transparent;

          /deep/.el-input__inner {
            background-color: transparent;
            border: 0;
            height: 24px;
            color: #fff;
            font-size: 16px;
          }

          /deep/.el-input__inner::placeholder {
            font-size: 16px;
            user-select: none;
            color: rgba(255, 255, 255, 0.45);
          }
        }

        /deep/.el-input__suffix {
          top: -6px;
        }

        .eyes {
          width: 16.667px;
          height: 14.167px;
          flex-shrink: 0;
        }
      }

      .btnOut {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 14px;

        .btn {
          width: 49.0196%;
          border: 0;
          display: flex;
          height: 52px;
          padding: 4px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          border-radius: 8px;
          background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);
          color: #fff;
          text-align: center;
          font-family: "PingFang SC";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
        }

        .register {
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.09);
        }
      }

      .tab2Btn {
        margin-top: 34px;
      }

      .dibu {
        user-select: none;
        width: 100%;
        margin: 0 auto 0 auto;

        .titles {
          color: rgba(255, 255, 255, 0.65);

          /* 内容/M-大多数文字 */
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 20px;
        }

        .imgDemo {
          display: flex;
          align-items: center;
          margin: 20px auto 0 auto;
          width: 60%;
          justify-content: center;
          gap: 60px;

          img {
            width: 44px;
            height: 63px;
          }
        }
      }

      .encounter {
        display: flex;
        align-items: center;
        white-space: nowrap;
        justify-content: center;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;

        img {
          cursor: pointer;
                user-select: none;
          margin: 0 5px 0 10px;
        }

        span {
          cursor: pointer;
                user-select: none;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          background: linear-gradient(180deg, #fff -54.54%, #c4deff 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
</style>
