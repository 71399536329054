<template>
  <el-dialog
    class="withDepostDialog"
    :visible="convertShow"
    @close="closeDialog('all')"
    :show-close="false"
  >
    <div class="diaTitle">
      <div class="diaTit">场馆转账</div>
      <img src="@/assets/postWith/titleColse.svg" @click="closeDialog('all')" />
    </div>
    <div class="contents">
      <div class="withTop">
        <div class="blueTop">
          <div class="blueLeft">
            <div class="leftTop">
              <div class="blueTitle">
                中心钱包
                <img
                  class="reload"
                  @click="init()"
                  :class="{
                    rotate:
                      balanceLoading || getWalletLoading || recoveryLoading,
                  }"
                  src="@/assets/postWith/sgya.svg"
                />
              </div>
              <div class="blueRight" @click="recovery">
                <img
                  src="@/assets/postWith/blueSx.svg"
                  :class="{
                    rotate:
                      balanceLoading || getWalletLoading || recoveryLoading,
                  }"
                />
                一键回收
              </div>
            </div>
            <div class="numbers">
              <img src="@/assets/postWith/money.svg" />
              {{ Math.trunc(totalAmount).toFixed(2) | formatNumber }}
            </div>
          </div>
          <div class="shu"></div>
          <div class="blue-new-right">
            <div class="blueTitle">锁定金额</div>
            <div class="subNumbers">
              <img src="@/assets/postWith/money.svg" />
              {{ Math.trunc(freeze) | formatNumber }}
            </div>
          </div>
        </div>
        <!-- <div class="openCo">
          <van-switch
            size="16px"
            class="open"
            inactive-color="rgba(255, 255, 255, 0.09)"
            v-model="checked"
          />隐藏无余额场馆
        </div>
        <div class="blueBom blueOne" v-show="!checked">
          <div class="withTit">
            <div class="withTitle">体育</div>
            <div class="cont">0.00</div>
          </div>
          <div class="bomShu"></div>
          <div class="withTit">
            <div class="withTitle">真人</div>
            <div class="cont">0.00</div>
          </div>
          <div class="bomShu"></div>
          <div class="withTit">
            <div class="withTitle">电子</div>
            <div class="cont">0.00</div>
          </div>
          <div class="bomShu"></div>
          <div class="withTit">
            <div class="withTitle">捕鱼</div>
            <div class="cont">0.00</div>
          </div>
          <div class="bomShu"></div>
          <div class="withTit">
            <div class="withTitle">区块链</div>
            <div class="cont">0.00</div>
          </div>
        </div> -->
      </div>

      <div class="wbxqblm">
        <div class="title flexSb" style="margin-bottom: 0; width: 100%">
          <div class="flexSb">
            <div class="blueVertical"></div>
            <div>场馆转账</div>
          </div>

          <div class="openCo">
            <van-switch
              size="16px"
              class="open"
              inactive-color="rgba(255, 255, 255, 0.09)"
              v-model="zzShow"
              @change="tipsShow"
              :loading="closeTransferLoading"
            />自动转账
          </div>
        </div>
        <!-- <div class="seleDemo">
          <div class="seleContent">
            <div
              class="seleList"
              v-for="(item, index) in list"
              :key="index"
              :class="{ active: actWith == index }"
              @click="selectAct(item, index)"
            >
              {{ item.name }}
              <div class="customBom"></div>
            </div>
          </div>
        </div> -->
      </div>

      <div class="wbxqblm">
        <div class="title flexSb" style="margin-bottom: 0; width: 100%">
          <div class="flexSb">
            <div class="blueVertical"></div>
            <div>钱包明细</div>
          </div>
          <div class="openCo">
            <van-switch
              size="16px"
              class="open"
              inactive-color="rgba(255, 255, 255, 0.09)"
              v-model="checked"
              @change="setShowList"
            />隐藏无余额场馆
          </div>
        </div>

        <div class="listCar">
          <el-carousel
            height="210px"
            :autoplay="false"
            indicator-position="none"
            v-if="itemLength > 0"
          >
            <el-carousel-item v-for="i in itemLength" :key="i + 10000">
              <div class="cgOut">
                <div
                  class="cgList"
                  v-for="(item, index) in visibleItems(i)"
                  :key="index"
                  @click="actWallet(item)"
                  :class="{ activeItem: activeWallet == item.id }"
                >
                  <div class="cgName">{{ item.name.includes("其他电子") ? "其他电子" : item.name }}
                    <el-popover
                placement="bottom"
                width="auto"
                trigger="click"
                :v-model.sync="visible"
                v-if="item.name.includes('其他电子')"
                :content="item.name.substring(4, item.name.length)"
                style="font-size: 12px"
              >
                <div
                  slot="reference"
                  @click="visible = !visible"
                  style="width: 15px; height: 15px"
                >
                  <img
                    style="width: 15px; height: 15px"
                    src="@/assets/postWith/iii.webp"
                  />
                </div>
              </el-popover>
                  </div>
                  <div class="cgOunt">{{ item.amount }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="selectRedio" v-show="!zzShow">
            <div class="seleDemoNew" @click="exchange(0)">
              <div class="yuan">
                <img
                  v-show="direction == 1"
                  src="@/assets/postWith/kyuan.png"
                />
                <img
                  v-show="direction == 0"
                  src="@/assets/postWith/blueYuan.png"
                />
              </div>
              <div class="zr">转入</div>
              <div class="seleName">场馆</div>
            </div>
            <div class="seleDemoNew" @click="exchange(1)">
              <div class="yuan">
                <img
                  v-show="direction == 0"
                  src="@/assets/postWith/kyuan.png"
                />
                <img
                  v-show="direction == 1"
                  src="@/assets/postWith/blueYuan.png"
                />
              </div>
              <div class="zc">转入</div>
              <div class="seleName">中心钱包</div>
            </div>
          </div>
        </div>
      </div>

      <div class="wbxqblm" v-show="!zzShow">
        <div class="title">
          <div class="blueVertical"></div>
          <div>转账金额</div>

          <div class="jrTs">
            <img src="@/assets/postWith/greenLamp.svg" />
            场馆内钱包不支持互转
          </div>
        </div>

        <div class="intPost">
          <div class="intLeft">
            <img class="dollarImg" src="@/assets/postWith/dollar.svg" />
            <el-input
              class="amoutInt"
              v-model="amount"
              type="number"
              :placeholder="`请输入转账金额`"
            ></el-input>
            <div class="intRight">
              <div class="rmbShu"></div>
              <div class="maxBtn" @click="allIn">最大</div>
            </div>
          </div>
        </div>
      </div>

      <div class="ckydwt">
        <div class="encounter">
          转账遇到问题？联系<img src="@/assets/login/service.svg"   @click="getService()" /><span   @click="getService()"
            >在线客服</span
          >
        </div>
        <van-button
          v-show="!zzShow"
          class="btn"
          :loading="
            transferLoading ||
            balanceLoading ||
            getWalletLoading ||
            recoveryLoading
          "
          :disabled="
            amount <= 0 ||
            JSON.stringify(walletIn) == '{}' ||
            JSON.stringify(walletOut) == '{}'
          "
          @click="submit"
          >立即取款</van-button
        >
      </div>

      <!-- <div class="ckydwt">取款遇到问题？联系<span>人工客服</span>解决</div> -->

      <!-- <van-popup v-model="showTips" class="popOuts" :close-on-click-overlay="false">
            <div class="popTips">
                <div class="popTop">
                    <div class="popTitle">重要提醒</div>
                    <div class="popCont">
                        请先去安全中心设置邮箱及资金密码
                    </div>
                </div>
                <van-button :disabled="time > 0" class="popBtn" :loading="fiveLoading" @click="closeTips">我知道了<span
                        v-show="time > 0">({{ time }}s)</span></van-button>
            </div>
        </van-popup> -->
      <div class="marks" v-show="checkedShow" @click.self="closeTipsNew">
        <div class="tipsPop">
          <div class="popTit">
            温馨提示 
            <div class="xxpop" @click="closeTipsNew"></div>
          </div>
          <div class="popBom">
            <div class="popContNew">{{ `是否${dkgb}自动转账功能？` }}</div>
            <div class="btnLine">
              <van-button class="tsClose" @click="closeTipsNew">取消</van-button>
              <van-button class="tipsOk" @click="openOrClose">确定</van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  platformWallet,
  platformWallet_balance,
  platformRecovery,
  platformTransfer,
  platformChange_auto_transfer,
  userInfo,
  platformBalance,
} from "@/request/api";
import { mapState, mapActions } from "vuex";
export default {
  components: {},
  props: {
    transferShow: Boolean,
  },
  name: "transferIndex",
  data() {
    return {
      getUserLoading: false,
      balance: 0,
      lockAmount: 0,
      getchannelLoading: false,
      walletLoading: false,
      checked: false,
      zzShow: true,
      walletShow: false,
      activeWallet: 0,
      walletList: [],
      walletShowList: [],
      walletIn: {},
      walletOut: { id: 0, name: "中心钱包" },
      direction: 0,
      amount: "",
      balanceLoading: false,
      getWalletLoading: false,
      recoveryLoading: false,
      transferLoading: false,
      closeTransferLoading: false,
      itemLength: 0,
      checkedShow:false,
      dkgb:"",
      totalAmount:0,
      freeze: 0,
      visible: false,
    };
  },
  computed: {
    ...mapState({
      convertShow: (state) => state.popShow.convertShow,
    }),
  },
  mounted() {
    this.getPlatformWallet_balance();
    this.getUserInfo();
  },
  methods: {
    ...mapActions(["setActivePop"]),
    closeDialog(fieldName) {
      this.setActivePop(fieldName);
    },
    setShowList(e) {
      if (e) {
        this.walletShowList = this.walletList.filter((item) => item.amount > 0);
      } else {
        this.walletShowList = this.walletList;
      }
      this.itemLength = Math.ceil(this.walletShowList.length / 15);
      // v-show="checked ? item.amount>0 : true"
    },
    visibleItems(page) {
      const start = (page - 1) * 15;
      const end = start + 15;
      return this.walletShowList.slice(start, end);
    },
    upLoginShow(newValue) {
      this.$emit("update:transferShow", newValue);
    },
    closeTips() {
      this.$router.push("/setup");
    },
    getService(){
                this.$store.commit("goService")
            },
    getUserInfo() {
      this.closeTransferLoading = true;
      userInfo().then((res) => {
        if (res.code == 0) {
          this.zzShow = res.data.isAutoTransfer;
        }
        this.closeTransferLoading = false;
      });
    },
    closeTipsNew(){
      this.checkedShow = false;
      this.zzShow = !this.zzShow;
      this.closeTransferLoading = false;
      console.log(this.zzShow,"关闭后")
    },
    tipsShow(e){
      this.closeTransferLoading = true;
      this.checkedShow = true;
      if(e){
        this.dkgb = "打开"
      }else{
        this.dkgb = "关闭"
      }
    },
    openOrClose() {
      console.log(this.zzShow)
      this.checkedShow = false;
      var opt = {
        val: this.zzShow,
      };
      platformChange_auto_transfer(opt).then((res) => {
        if (res.code == 0) {
          this.$message.success(`已${this.dkgb}免转钱包模式`);
        }
        this.closeTransferLoading = false;
        this.getUserInfo();
      });
    },
    submit() {
      if (
        JSON.stringify(this.walletIn) == "{}" ||
        JSON.stringify(this.walletOut) == "{}"
      ) {
        this.$message.warning("请选择钱包");
        return;
      }
      if (!this.amount) {
        this.$message.warning("请输入转账金额");
        return;
      }
      if (this.transferLoading) {
        return;
      }
      this.transferLoading = true;
      var opt = {
        direction: this.direction,
        amount: parseInt(this.amount),
      };
      if (this.direction == 0) {
        opt.walletId = this.walletIn.id;
      } else if (this.direction == 1) {
        opt.walletId = this.walletOut.id;
      }
      platformTransfer(opt).then((res) => {
        if (res.code == 0) {
          this.$message.success("转账成功,正在刷新钱包...若有延迟请稍后手动刷新");
          this.init();
        }
        this.transferLoading = false;
      });
    },
    recovery() {
      if (this.recoveryLoading) {
        return;
      }
      this.recoveryLoading = true;
      platformRecovery().then((res) => {
        if (res.code == 0) {
          this.balance =
            res.data.balance + res.data.totalAmount + res.data.freeze;
          this.totalAmount = res.data.balance;
          this.freeze = res.data.freeze;
          this.$message.success("回收成功");
          this.recoveryLoading = false;
          this.init();
        }else{
          this.recoveryLoading = false;
        }
        
      });
    },
    init() {
      if (
        this.balanceLoading ||
        this.getWalletLoading ||
        this.recoveryLoading
      ) {
        return;
      }
      this.walletList = [];
      this.activeWallet = 0;
      (this.walletIn = {}),
        (this.walletOut = { id: 0, name: "中心钱包" }),
        (this.direction = 0);
      this.getPlatformWallet_balance();
      this.getUserInfo();
      // this.getPlatformBalance();
    },
    allIn() {
      if (this.direction == 0) {
        //中心钱包
        //  this.amount  = this.walletIn.amount
        this.amount = Math.trunc(this.walletOut.amount);
      } else {
        this.amount = Math.trunc(this.walletOut.amount);
      }
    },
    openSelectWall(index) {
      if (index == this.direction) {
        this.walletShow = true;
      }
    },
    getPlatformBalance() {
      this.balanceLoading = true;
      platformBalance().then((res) => {
        if (res.code == 0) {
          this.balance = res.data.balance + res.data.totalAmount;
        }
        this.balanceLoading = false;
      });
    },
    getPlatformWallet_balance() {
      this.getWalletLoading = true;
      platformWallet_balance().then((res) => {
        if (res.code == 0) {
          this.balance =
            res.data.balance + res.data.totalAmount + res.data.freeze;
          this.totalAmount = res.data.balance;

          // this.walletList.push(...res.data.plat);
          this.freeze = res.data.freeze;
          this.walletList = res.data.plat.slice().sort((a, b) => {
            // 如果 a 是特殊项，排到最后
            if (a.name.length >= 7 && b.name.length < 7) return 1;
            // 如果 b 是特殊项，排到最后
            if (a.name.length < 7 && b.name.length >= 7) return -1;
            // 否则保持原有顺序

            return 0;
          });

          this.setShowList(false);
        }
        this.getWalletLoading = false;
      });
    },
    exchange(index) {
      if (JSON.stringify(this.walletIn) === "{}") {
        this.$message.warning("请选择钱包");
        return;
      }
      var exch = this.walletOut;
      this.walletOut = this.walletIn;
      this.walletIn = exch;
      // if (this.direction == 0) {
      //   this.direction = 1;
      // } else {
      //   this.direction = 0;
      // }
      this.direction = index;
    },
    actWallet(item) {
      this.activeWallet = item.id;
      if (this.direction == 0) {
        this.walletIn = item;
      } else {
        this.walletOut = item;
      }
      this.walletShow = false;
    },
    getplatformWallet() {
      platformWallet().then((res) => {
        console.log(res);
        this.walletList.push(...res.data);
      });
    },
  },
  filters: {
    truncatedNumber(number) {
      // 只保留小数点后两位
      return (Math.floor(number * 100) / 100).toFixed(2);
    },
    formatNumber(value) {
      if (!value) return value;
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style lang="less" scoped>
.withDepostDialog {
  /deep/.el-dialog {
    display: flex;
    width: 700px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    border-radius: 10px;
    background: radial-gradient(
      133.65% 137.5% at -19.87% -5.42%,
      #02568e 6%,
      #13293b 61.18%,
      #152b3f 100%
    );

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      width: 100%;
      padding: 20px;
    }
  }

  .diaTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    .diaTit {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
    }

    img {
      width: 40px;
      height: 40px;
    }
  }
}

.contents {
  padding-bottom: 20px;

  .wbxqblm {
    width: 100%;
    display: flex;
    padding: 10px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    border-radius: 10px;
    //border: 0.5px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.04);
    margin-top: 14px;

    .intPost {
      height: 48px;
      display: flex;
      padding: 12px 12px 12px 48px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.04);
      background: rgba(255, 255, 255, 0.09);

      margin-bottom: 6px;

      .intLeft {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;

        .dollarImg {
          width: 48px;
          height: 48px;
          flex-shrink: 0;
          fill: #ff3c44;
          filter: drop-shadow(0px 8px 12px rgba(255, 60, 68, 0.4));
          position: absolute;
          left: -48px;
          top: 6px;
        }

        .amoutInt {
          /deep/.el-input__inner {
            padding-left: 0;
            background: transparent;
            border: 0;
            color: #fff;
          }

          /deep/.el-input__inner::-webkit-outer-spin-button,
          /deep/.el-input__inner::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            -moz-appearance: none !important;
            -o-appearance: none !important;
            -ms-appearance: none !important;
            appearance: none !important;
            margin: 0;
          }

          input[type="number"] {
            -webkit-appearance: textfield;
            -moz-appearance: textfield;
            -o-appearance: textfield;
            -ms-appearance: textfield;
            appearance: textfield;
          }
        }

        .colse {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .intRight {
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(255, 255, 255, 0.65);
          /* 内容/M-大多数文字 */
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 200;

          .rmbShu {
            width: 1px;
            height: 12px;
            background: rgba(255, 255, 255, 0.45);
            margin: 0 10px;
          }

          .maxBtn {
            color: #0385ff;
            line-height: 44px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            font-family: "PingFang SC";
            white-space: nowrap;
          }
        }
      }

      .intRight {
        color: rgba(255, 255, 255, 0.45);
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
  }

  .withTop {
    display: flex;
    width: 100%;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.04);
    padding-bottom: 12px;
    flex-wrap: wrap;
    margin: 12px auto 0 auto;
    transition: all 1s;

    .blueTop {
      border-radius: 8px;
      background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 12px;

      .blueLeft {
        .leftTop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 300px;
        }

        .blueTitle {
          display: flex;
          align-items: center;
          color: #fff;
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;

          img {
            width: 12px;
            height: 12px;
            margin-left: 4px;
          }
        }

        .numbers {
          color: #fff;
          font-family: DIN;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          text-align: left;
          margin-top: 2px;

          img {
            width: 16px;
            height: 16px;
          }
        }

        .twoTt {
          margin-top: 10px;
        }

        .blueRight {
          height: 22px;

          color: #fff;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          border-radius: 18px;
          background: rgba(255, 255, 255, 0.25);
          display: flex;
          padding: 2px 6px;
          justify-content: center;
          align-items: center;
          gap: 2px;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .shu {
        width: 0.5px;
        height: 54px;
        background: rgba(255, 255, 255, 0.25);
        margin: 0 auto;
      }

      .blue-new-right {
        width: 300px;

        .blueTitle {
          text-align: left;
          color: #fff;
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }

        .subNumbers {
          color: #fff;
          font-family: DIN;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          text-align: left;
          margin-top: 2px;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .openCo {
      color: #fff;
      /* 内容/XS-辅助性文字 */
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 200;
      display: flex;
      align-items: center;
      padding-left: 10px;
      margin-top: 12px;

      .open {
        margin-right: 3px;
      }
    }

    .blueOne {
      margin-top: 12px;
    }

    .blueBom {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;

      .withTit {
        width: 19%;

        .withTitle {
          color: rgba(255, 255, 255, 0.65);
          text-align: center;
          width: 100%;
          /* 内容/XS-辅助性文字 */
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
        }

        .cont {
          color: #fff;
          text-align: center;
          font-family: DIN;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          margin-top: 2px;
        }
      }

      .bomShu {
        width: 0.5px;
        height: 20px;
        background: rgba(255, 255, 255, 0.25);
      }
    }
  }

  .title {
    width: 100%;
    margin: 0 auto 14px auto;
    color: #fff;
    font-family: "PingFang SC";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .blueVertical {
      width: 4px;
      height: 32px;
      border-radius: 0px 8px 8px 0px;
      background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);
      margin-right: 10px;
    }

    .titRight {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        margin-left: 3px;
      }
    }

    .titBtn {
      color: #0385ff;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.09);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 59px;
      margin-left: 10px;
      height: 22px;
      margin-top: 5px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 1px;
      }
    }

    .jrTs {
      display: inline-flex;
      padding: 5px 10px;
      align-items: center;
      gap: 5px;
      flex: 1 0 0;
      border-radius: 10px;
      background: rgba(44, 209, 88, 0.09);
      color: rgba(255, 255, 255, 0.45);
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      margin-left: 10px;
      margin-top: 4px;
      text-align: left;
      min-width: 100px;
      max-width: 170px;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .seleDemo {
    width: 100%;
    overflow-x: auto;

    .seleContent {
      display: flex;
    }

    .seleList {
      position: relative;
      min-width: 96px;
      height: 47.731px;
      padding: 4px 16px;
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.15);
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-right: 12px;

      .customBom {
        opacity: 0;
        position: absolute;
        right: 0px;
        bottom: 0px;
        background-image: url("../../assets/postWith/newd.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 20px;
        height: 20px;
      }
    }

    .active {
      background: #283e4c;
      border: 1px solid #0385ff;

      .customBom {
        opacity: 1 !important;
      }
    }
  }

  .seleDemo::-webkit-scrollbar {
    top: 5px;
  }

  .tis {
    color: #fff;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    /* 内容/S-辅助性文字 */
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 100;
  }

  .ckydwt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 14px;

    .encounter {
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: center;

      img {
        cursor: pointer;
        margin: 0 5px 0 10px;
      }

      span {
        cursor: pointer;
        user-select: none;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        background: linear-gradient(180deg, #fff -54.54%, #c4deff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .btn {
      display: flex;
      width: 280px;
      height: 46px;
      padding: 4px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 0;
      background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);
      color: #fff;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 24px */
    }
  }

  .linkDemo {
    margin-top: 10px;

    .linkContent {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0 auto;
      height: 40px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.09);

      .linkLeft {
        display: flex;
        align-items: center;

        .btTit {
          font-weight: 100;
        }

        .linkShu {
          width: 1px;
          height: 20px;
          border-radius: 3px;
          background: rgba(255, 255, 255, 0.35);
          margin: auto 10px;
        }
      }

      .dhRight {
        width: 20px;
        height: 16px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .withInt {
    display: flex;
    width: 100%;
    margin: 10px auto;
    padding: 12px;
    align-items: center;
    gap: 10px;
    display: flex;
    justify-content: space-between;
    height: 45px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.04);
    background: var(---4, rgba(255, 255, 255, 0.04));

    .ints {
      background: transparent;
      border: 0;
      height: 45px;
      color: #fff;
      padding-left: 0;
      width: 72%;
    }
  }

  .yuji {
    color: rgba(255, 255, 255, 0.65);
    /* 内容/S-辅助性文字 */
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin: 2px auto 0 auto;
    width: 100%;
    justify-content: space-between;

    span {
      color: #0385ff;
    }
  }

  .wlfb {
    color: rgba(255, 255, 255, 0.45);
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 200;
    width: 100%;
    margin: 15px auto 0 auto;
    text-align: left;
  }
}

.popOuts {
  background: transparent;
}

.popTips {
  border-radius: 8px;
  background: #1e303a;
  overflow: hidden;

  .popTop {
    width: 290px;
    height: 90px;
    padding: 12px;
    border-radius: 8px 8px 0 0;

    .popTitle {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      text-align: center;
      margin-bottom: 25px;
    }

    .popCont {
      color: #ff4337;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .popBtn {
    width: 245px;
    height: 48px;
    color: #0385ff;
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    border: 0;
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid #19262e;
    background: #1e303a;
  }
}

/deep/.van-field__control {
  color: #fff;
}

.reload {
}
.rotate {
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.flexSb {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  .openCo {
    color: #fff;
    /* 内容/XS-辅助性文字 */
    font-family: "PingFang SC";
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;

    .open {
      margin-right: 3px;
    }
  }
}

.listCar {
  width: 100%;
  margin-top: 10px;
  .cgOut {
    width: 96%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, 110px);
    grid-row-gap: 10px;
    grid-gap: 10px;
    justify-content: flex-start;
    transition: all 0.5s ease;
    .cgList {
      cursor: pointer;
      width: 110px;
      height: 60px;
      display: flex;
      flex-direction: column;
      place-content: center;
      place-items: center;
      flex-shrink: 0;
      padding: 5px 0px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.04);
      transition: all 0.5s ease;
      .cgName {
        color: rgba(177, 186, 211, 1);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 12px;
        display: flex;
        align-items: center;
      }
      .cgOunt {
        text-align: center;
        color: rgba(177, 186, 211, 1);
        font-family: DIN;
        font-weight: 700;
        font-size: 16px;
      }
    }
    .cgList:hover {
      background: linear-gradient(
        91.96deg,
        rgba(3, 133, 255, 0.2) 0%,
        rgba(3, 89, 255, 0.4) 136.81%
      );
      .cgName {
        color: #fff;
      }
      .cgOunt {
        color: #fff;
      }
    }
    .activeItem {
      padding: 5px 0px;
      border-radius: 8px;
      background: linear-gradient(
        91.96deg,
        rgba(3, 133, 255, 1) 0%,
        rgba(3, 89, 255, 1) 136.81%
      );
      .cgName {
        color: #fff;
      }
      .cgOunt {
        color: #fff;
      }
    }
  }
}
.selectRedio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  .seleDemoNew {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    .yuan {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .zr {
      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 14px;
      padding: 0px 5px;
      line-height: 20px;
      border-radius: 4px;
      background: linear-gradient(
        91.96deg,
        rgba(3, 133, 255, 1) 0%,
        rgba(3, 89, 255, 1) 136.81%
      );
      margin-left: 10px;
    }
    .zc {
      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 14px;
      padding: 0px 5px;
      line-height: 20px;
      border-radius: 4px;
      background: linear-gradient(
        91.96deg,
        rgba(12, 181, 78, 1) 0%,
        rgba(0, 144, 130, 1) 136.81%
      );
      margin-left: 10px;
    }
    .seleName {
      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 16px;
      margin-left: 10px;
    }
  }
}
/deep/.el-carousel--horizontal {
  width: 100%;
}

.marks {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2208;
  .tipsPop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 480px;
    height: 238px;
    .popTit {
      height: 52px;
      text-align: center;
      display: flex;
      flex-direction: column;
      place-content: center;
      place-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 10px 10px 0px 0px;
      background: linear-gradient(
        91.96deg,
        rgba(3, 133, 255, 1) 0%,
        rgba(3, 89, 255, 1) 136.81%
      );
      position: relative;
      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 20px;
      .xxpop {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        background: url("@/assets/postWith/xx.png") no-repeat;
        background-size: 100% 100%;
        width: 42px;
        height: 40px;
      }
    }
    .popBom {
      padding: 20px;
      border-radius: 0px 0px 10px 10px;
      background: rgb(30, 48, 58);
      .popContNew {
        height: 80px;
        line-height: 80px;
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 16px;
      }
      .btnLine {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .tsClose {
          width: 153px;
          height: 46px;
          display: flex;
          place-content: center;
          place-items: center;
          gap: 10px;
          flex-shrink: 0;
          // padding: 4px 60px;
          border-radius: 8px;
          background: transparent;
          border: 1px solid rgba(3, 133, 255, 1);
          box-sizing: border-box;
          color: rgba(3, 133, 255, 1);
          font-family: "PingFang SC";
          font-weight: 400;
          font-size: 16px;
        }
        .tipsOk {
          width: 153px;
          height: 46px;
          display: flex;
          place-content: center;
          place-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 8px;
          background: linear-gradient(
            91.96deg,
            rgba(3, 133, 255, 1) 0%,
            rgba(3, 89, 255, 1) 136.81%
          );
          text-align: center;
  color: rgba(255, 255, 255, 1);
  font-family: "PingFang SC";
  font-weight: 400;
  font-size: 16px;
  border: 0;
        }
      }
    }
  }
}
</style>
