<template>
  <div class="leftHead" :style="{ width: isExpand ? '240px' : '60px' }">
    <div
      class="headTop"
      :style="{
        gap: isExpand ? '10px' : '0px',
        width: isExpand ? '240px' : '60px',
      }"
    >
      <div class="threeTraverse" @click="expand()">
        <img src="@/assets/newHead/three.webp" draggable="false" />
      </div>
      <div class="ylc" @click="$router.push('/')" v-show="isExpand">
        <img
          :src="
            require($route.path == '/'
              ? `@/assets/newHead/top/ylc1.webp`
              : `@/assets/newHead/top/ylc0.webp`)
          "
          draggable="false"
        />
      </div>
      <div class="ylc tycs" v-show="isExpand" @click="goTy()">
        <img src="@/assets/newHead/top/ty0.webp" draggable="false" />
        <!-- <div class="popzwkf">
          <img src="@/assets/newHead/zwkf.webp" draggable="false" />
        </div> -->
      </div>
    </div>
    <div style="width: 100%; height: 60px"></div>

    <div class="leftBom" :class="{ wide: isExpand, narrow: !isExpand }">
      <div class="myHead">
        <div class="heads">
          <div class="avatar" @click="isLogin ? (headShow = true) : ''">
            <img
              :src="
                userData.headImg
                  ? userData.headImg
                  : require('@/assets/personal/logo.webp')
              "
              draggable="false"
            />
          </div>
          <div class="liveDemo" v-show="isExpand && isLogin">
            {{ `VIP.${myData.currLevel || 0}` }}
          </div>
        </div>
        <div class="myName" v-show="isExpand">{{ userData.username }}</div>
        <div class="myLine" v-show="isExpand">
          <div class="starts" v-show="isLogin">
            <img src="@/assets/newHead/start.webp" draggable="false" />
          </div>
          <div v-show="isLogin">这是您加入的第{{ userData.registerDay }}天</div>
        </div>
      </div>

      <div class="homeAndSport" v-show="!isExpand">
        <div class="smallYlc" @click="$router.push('/')">
          <img
            :src="
              require($route.path == '/'
                ? `@/assets/newHead/top/smylc1.webp`
                : `@/assets/newHead/top/smylc0.webp`)
            "
          />
        </div>
        <div class="smallYlc smallTy">
          <img src="@/assets/newHead/top/smty0.webp" draggable="false" />
          <div class="popzwkf">
            <img src="@/assets/newHead/zwkf.webp" draggable="false" />
          </div>
        </div>
      </div>

      <div class="listOut" v-for="(item, index) in sidebarList" :key="index" :style="{ width: isExpand ? '100%' : '44px' }">
       <div
          class="listLine"
          v-for="(child, y) in item.chlidren"
          :key="index + y + 100"
          :class="{
            selectSid: $route.path == child.path,
            itemShowKfList: child.isKaifa,
            topRadiu: y == 0,
            bomRadiu: y == item.chlidren.length - 1,
            popzwkfXzxxx: child.id == 15,
          }"
          v-show="child.isLogin ? isLogin : true"
          @click="goLink(child)"
        >
          <div class="listLeftImg">
            <img
              :src="$route.path == child.path ? child.icons : child.icon"
              draggable="false"
            />
          </div>
          <div class="listName" v-show="isExpand">{{ child.name }}</div>
          <div
            class="infoNum"
            v-show="child.id == 8 && isExpand && noReadCount > 0"
          >
            {{ noReadCount + "+" }}
          </div>
          <div
            class="infoNum"
            v-show="child.id == 10 && isExpand && waitReceiveCount > 0"
          >
            {{ waitReceiveCount  }}
          </div>
          <div class="popzwkfItem">
            <img src="@/assets/newHead/zwkf.webp" draggable="false" />
          </div>
          <!-- <div class="popzwkfXz">
            
          </div> -->
          <el-tooltip popper-class="downPop"  placement="right-start" :visible-arrow="false" v-model="downShow"  v-if="child.id == 15">
          <template #content>
            <div class="newDown">
                <div class="ewm" >
              <div
                class="ewmQR"
                :ref="child.id == 15 ? 'qrCodeUrlLeft' : null"
              ></div>
            </div>
            </div>
            
            </template>
            <div class="xzOut"></div>
            </el-tooltip>
        </div>
    
       
      </div>
    </div>

    <el-dialog
      class="loginOutDialog"
      :visible="outLoginShow"
      @close="closeDialog('all')"
      :show-close="false"
    >
      <div class="outTitle">
        <div class="outTitLeft">
          <div class="outTitImg">
            <img src="@/assets/newHead/list/15.webp" draggable="false" />
          </div>
          <div>登出</div>
        </div>
        <div class="titRightClose" @click="closeDialog('all')">
          <img src="@/assets/newHead/close.webp" draggable="false" />
        </div>
      </div>
      <div class="outLoginCont">
        别忘了在您离开之前先查看我们丰富的娱乐城与体育博彩促销活动！
      </div>
      <van-button class="outLogBtn" :loading="subLoading" @click="outLogin()"
        >登出</van-button
      >
    </el-dialog>

    <el-dialog
      class="setHeadPop"
      :visible="headShow"
      @close="headShow = false"
      :show-close="false"
    >
      <div class="diaTitle">
        <div class="diaTit">更换头像</div>
        <img src="@/assets/postWith/titleColse.svg" @click="headShow = false" />
      </div>

      <div class="listOut">
        <div
          class="imgList"
          v-for="(item, index) in 20"
          :key="index"
          @click="active = index"
        >
          <img
            :src="`https://pd520.s3.ap-east-1.amazonaws.com/pd/user/head/${index}.webp`"
          />
          <div v-show="active == index" class="tips">使用中</div>
        </div>
      </div>
      <van-button
        class="headBtn"
        :loading="headSubLoading"
        :disabled="headSubLoading || active < 0"
        @click="submit"
        >更换</van-button
      >
    </el-dialog>
   
  </div>
</template>

<script>
import { EventBus } from '@/util/event-bus'; 
import QRCode from "qrcodejs2";
import { mapState, mapActions } from "vuex";
import {
  userInfo,
  userHome,
  syslogout,
  platformEeturn_recovery,
  userSetting_headimg,
  welfareWait_receive
} from "@/request/api";
export default {
  name: "leftHead",
  data() {
    return {
      userData: {
        username: "游客",
      },
      sidebarList: [
        {
          chlidren: [
            {
              name: "存款",
              icon: require("@/assets/newHead/list/0.webp"),
              icons: require("@/assets/newHead/list/yes0.webp"),
              id: 1,
              path: "/deposit",
              isLogin: true,
            },
            {
              name: "取款",
              icon: require("@/assets/newHead/list/1.webp"),
              icons: require("@/assets/newHead/list/yes1.webp"),
              id: 2,
              path: "/withdrawals",

              isLogin: true,
            },
            {
              name: "转账",
              icon: require("@/assets/newHead/list/2.webp"),
              icons: require("@/assets/newHead/list/yes2.webp"),
              id: 3,
              path: "/convert",
              isLogin: true,
            },
          ],
        },
        {
          chlidren: [
            {
              name: "个人中心",
              icon: require("@/assets/newHead/list/3.webp"),
              icons: require("@/assets/newHead/list/yes3.webp"),
              id: 4,
              path: "/personal",
              isLogin: true,
            },
            {
              name: "交易记录",
              icon: require("@/assets/newHead/list/4.webp"),
              icons: require("@/assets/newHead/list/yes4.webp"),
              id: 5,
              path: "/record",
              isLogin: true,
            },
            {
              name: "投注记录",
              icon: require("@/assets/newHead/list/5.webp"),
              icons: require("@/assets/newHead/list/yes5.webp"),
              id: 6,
              path: "/record/postRecord",
              isKaifa: false,
              isLogin: true,
            },
            {
              name: "安全中心",
              icon: require("@/assets/newHead/list/6.webp"),
              icons: require("@/assets/newHead/list/yes6.webp"),
              id: 7,
              path: "/setup",
              isLogin: true,
            },
            {
              name: "我的消息",
              icon: require("@/assets/newHead/list/7.webp"),
              icons: require("@/assets/newHead/list/yes7.webp"),
              id: 8,
              path: "/info/index",
              isLogin: true,
            },
          ],
        },
        {
          chlidren: [
            {
              name: "游戏提供商",
              icon: require("@/assets/newHead/list/8.webp"),
              icons: require("@/assets/newHead/list/yes8.webp"),
              id: 9,
              path: "/",
            },
          ],
        },
        {
          chlidren: [
            {
              name: "促销活动",
              icon: require("@/assets/newHead/list/9.webp"),
              icons: require("@/assets/newHead/list/yes9.webp"),
              id: 10,
              path: "/activityOut",
            },
            {
              name: "邀请好友",
              icon: require("@/assets/newHead/list/10.webp"),
              icons: require("@/assets/newHead/list/yes10.webp"),
              id: 11,
              path: "/share/index",
              isLogin: true,
            },
            {
              name: "福利中心",
              icon: require("@/assets/newHead/list/11.webp"),
              icons: require("@/assets/newHead/list/yes11.webp"),
              id: 12,
              path: "/welfare/index",
              isLogin: true,
            },
            {
              name: "VIP中心",
              icon: require("@/assets/newHead/list/12.webp"),
              icons: require("@/assets/newHead/list/yes12.webp"),
              id: 13,
              path: "/vip",
              isLogin: true,
            },
          ],
        },
        {
          chlidren: [
          {
              name: "联盟计划",
              icon: require("@/assets/newHead/list/10.webp"),
              icons: require("@/assets/newHead/list/yes10.webp"),
              id: 17,
              path: "/cooperation",
            },
            {
              name: "在线客服",
              icon: require("@/assets/newHead/list/13.webp"),
              icons: require("@/assets/newHead/list/yes13.webp"),
              id: 14,
              path: "/zxkf",
            },
            {
              name: "下载APP",
              icon: require("@/assets/newHead/list/14.webp"),
              icons: require("@/assets/newHead/list/yes14.webp"),
              id: 15,
              path: "/download",
            },
            {
              name: "登出",
              icon: require("@/assets/newHead/list/15.webp"),
              icons: require("@/assets/newHead/list/yes15.webp"),
              id: 16,
              path: "/outLogin",
              isLogin: true,
            },
          ],
        },
      ],
      isLogin: false,
      myData: {},
      userType: false,
      noReadCount: 0,

      subLoading: false,
      downShow:false,
      // 更换头像
      headShow: false,
      active: -1,
      headSubLoading: false,
      homeImg: "",
      waitReceiveCount:0,
    };
  },
   watch: {
    $route(to,from) {
      if(to.path != from){
        this.isLogin = localStorage.getItem("token");
    if (this.isLogin) {
      // this.getUserInfo();
      // this.getUserHome();
      this.getPlatformEeturn_recovery();
    }
      }
    },
  },
  computed: {
    ...mapState({
      isExpand: (state) => state.isExpand,
      outLoginShow: (state) => state.popShow.outLoginShow,
    }),
  },
  created() {
    this.isLogin = localStorage.getItem("token");
    if (this.isLogin) {
      // this.getUserInfo();
      // this.getUserHome();
      this.getPlatformEeturn_recovery();
    }
  },
  beforeDestroy() {},
  mounted() {
    this.linkQR();
    if (this.$route.query.code !== undefined) {
      localStorage.setItem("myCode", this.$route.query.code);
    }
    if (this.$route.query.url !== undefined) {
      localStorage.setItem("inUrl", this.$route.query.url);
    }
  },
  methods: {
    ...mapActions(["setActivePop"]),
    setOutLoginShow() {
      this.$store.commit("setOutLoginFalse");
    },
    goTy(){
      this.$router.push('/?tabs=2')
      EventBus.$emit('goTy', 2);
    },
    linkQR() {
      this.$nextTick(() => {
        new QRCode(this.$refs.qrCodeUrlLeft[0], {
          text: "https://pdty.duuws.top", // 需要转换为二维码的内容
          width: 120,
          height: 120,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
    getPlatformEeturn_recovery() {
      platformEeturn_recovery().then((res) => {
        // this.getPlatformBalance();
        this.getUserInfo();
        this.getUserHome();
        this.getLq();
        if (res.code == 0) {
          // this.getPlatformBalance();
          // this.getUserInfo()
        } else {
          // this.getPlatformEeturn_recovery()
        }
      });
    },
    handleBeforeClose(done) {
      // If you need to perform any actions before closing, you can use done callback
      done();
    },
    outLogin() {
      this.subLoading = true;
      syslogout().then((res) => {
        if (res.code == 0) {
          localStorage.removeItem("token");
          localStorage.removeItem("downShow");
          this.userData= {
        username: "游客",
      },
          // localStorage.removeItem("isExpand");
          this.$message.success("退出成功");

          setTimeout(() => {
            // this.setOutLoginShow();
            this.setActivePop("all");
            if (this.$route.path == "/") {
              window.location.reload();
            } else {
              this.$router.push("/");
            }
          }, 1000);
        }
        this.subLoading = false;
      });
    },
    closeDialog(fieldName) {
      this.setActivePop(fieldName);
    },
    // handleCloseOutLogin(){
    //   this.setActivePop("all");
    // },
    expand() {
      this.$store.commit("setIsExpand");
    },
    goLink(item) {
      if (item.isKaifa) {
        return;
      }
      switch (item.id) {
        case 1:
          this.setActivePop("depositShow");
          break;
        case 2:
          this.setActivePop("withdrawalShow");
          break;
        case 3:
          this.setActivePop("convertShow");
          break;

        case 16:
          this.setActivePop("outLoginShow");
          break;
        case 14:
          this.$store.commit("goService");
          break;
        case 15:
          this.downShow = true;
          break;
        default:
          this.$router.push(item.path);
          break;
      }
    },
    getUserInfo() {
      this.getUserLoading = true;
      userInfo().then((res) => {
        if (res.code == 0) {
          var balan = res.data.balance.toFixed(2);
          var dotIndex = balan.indexOf(".");
          if (dotIndex == -1) {
            res.data.balance = `${res.data.balance}.00`;
          }
          this.balanceOne = balan.slice(0, dotIndex + 1);
          this.balanceTwo = balan.slice(dotIndex + 1);
          this.userData = res.data;
          this.userType = true;

          this.homeImgLink = res.data.headImg.substring(
            0,
            res.data.headImg.lastIndexOf("/") + 1
          );
          var name = res.data.headImg.substring(
            res.data.headImg.lastIndexOf("/") + 1
          );
          if (name.substring(0, name.lastIndexOf(".")) == "default") {
            this.active = 0;
          } else {
            this.active = name.substring(0, name.lastIndexOf("."));
          }
        }
        this.getUserLoading = false;
      });
    },
    submit() {
      this.headSubLoading = true;
      var opt = {
        imgIndex: this.active,
      };
      userSetting_headimg(opt).then((res) => {
        if (res.code == 0) {
          this.$message.success("更换成功");
          setTimeout(() => {
            window.location.reload();
          }, 800);
        }
        this.headSubLoading = false;
      });
    },
    getUserHome() {
      userHome().then((res) => {
        if (res.code == 0) {
          this.noReadCount = res.data.noReadCount;
          
          this.myData = res.data;
        }
      });
    },
    getLq(){
      welfareWait_receive().then((res) => {
        if (res.code == 0) {
          this.waitReceiveCount = res.data.length;
        }
        this.getHomeLoading = false;
      })
    },
  },
};
</script>

<style lang="less" scoped>
.leftHead {
  height: 100dvh;
  transition: all 0.2s ease;
  background: #0f212e;
  user-select: none;
  overflow-y: auto; /* 仅在垂直方向上添加滚动条 */
  overflow-x: hidden; /* 水平方向上的内容溢出显示 */
  padding-bottom: 80px;

  .headTop {
    width: 100%;
    transition: all 0.2s ease;
    height: 60px;
    background: rgba(16, 27, 31, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: fixed;
    top: 0;
    z-index: 500;
    .threeTraverse {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .ylc {
      width: 78px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .leftBom {
    width: 100%;
    height: calc(100dvh - 60px);
    transition: all 0.2s ease;

    .myHead {
      position: relative;
      overflow: hidden;
      width: 100%;
      border-radius: 4px;
      box-sizing: border-box;

      .heads {
        position: relative;

        .avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }

        .liveDemo {
          width: 61px;
          height: 19px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -9px;
          margin: auto;
          background: url("@/assets/newHead/live.webp") no-repeat 100% 100%;
          background-size: 100% 100%;
          font-size: 12px;
          line-height: 19px;
        }
      }

      .myName {
        color: rgba(255, 255, 255, 1);
        font-family: DIN;
        font-weight: 700;
        font-size: 16px;
        margin-top: 18px;
      }

      .myLine {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 10px;

        .starts {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .homeAndSport {
      margin-top: 6px;
      .smallYlc {
        border-radius: 4px 4px 0 0;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px 4px 0 0;
        }
      }
      .smallTy {
        border-radius: 0 0 4px 4px !important;
        img {
          border-radius: 0 0 4px 4px !important;
        }
      }
    }

    .listOut {
      margin-top: 5px;
      border-radius: 4px;
      // overflow: hidden;
      .listLine {
        background: rgba(26, 44, 56, 1);
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        padding-left: 15px;
        position: relative;
        cursor: pointer;
        .listLeftImg {
          width: 14px;
          height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .xzOut{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        .listName {
          color: rgba(255, 255, 255, 1);
          font-family: "PingFang SC";
          font-weight: 400;
          font-size: 14px;
          line-height: 45px;
        }
        .infoNum {
          display: flex;
          padding: 0px 5px;
          align-items: center;
          gap: 10px;
          border-radius: 22px;
          background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);
          color: #fff;
          font-family: "SF Pro Display";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          position: absolute;
          right: 15px;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 15px;
          line-height: 15px;
        }
      }
      .listLine:hover {
        background: rgba(26, 44, 56, 0.65);
      }
      .topRadiu {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      .bomRadiu {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .selectSid {
        background: rgba(33, 55, 67, 1);
      }
    }
  }

  .wide {
    padding: 16px;

    .myHead {
      height: auto;
      background: rgba(26, 44, 56, 1);
      padding-top: 24px;
      padding-bottom: 24px;

      .avatar {
        width: 60px;
        height: 60px;
      }
    }

    .myHead::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 204px;
      /* 圆角半径 */
      border: 0.5px solid transparent;
      /* 边框宽度和透明色 */
      border-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(102, 102, 102, 0) 100%
        )
        1;
      /* 渐变边框 */
      pointer-events: none;
      /* 确保伪元素不阻止点击 */
    }
    .listLine {
      gap: 10px;
    }
  }

  .narrow {
    padding: 16px 8px 16px 6px;

    .myHead {
      height: 44px;

      .avatar {
        width: 44px;
        height: 44px;
      }
    }
  }
}

.tycs {
  position: relative;
  transition: all 0.2s ease;
  .popzwkf {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 253px;
    position: absolute;
    z-index: 500;
    left: 80px;
    top: 0px;
    transition: all 0.2s ease;
    visibility: hidden;
    opacity: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.tycs:hover .popzwkf {
  visibility: visible;
  transition: all 0.2s ease;
  opacity: 1;
}
.smallTy {
  position: relative;
  transition: all 0.2s ease;
  .popzwkf {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 253px;
    position: absolute;
    z-index: 500;
    left: 50px;
    top: 0px;
    transition: all 0.2s ease;
    visibility: hidden;
    opacity: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.smallTy:hover .popzwkf {
  visibility: visible;
  transition: all 0.2s ease;
  opacity: 1;
}
.itemShowKfList {
  // position: relative;
}
.popzwkfItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 217px;
  height: 253px;
  position: absolute;
  z-index: 500;
  left: 100%;
  top: -6px;
  transition: all 0.2s ease;
  visibility: hidden;
  opacity: 0;
  img {
    width: 100%;
    height: 100%;
  }
}
  .newDown{
     display: flex;
  align-items: center;
  justify-content: center;
  width: 217px;
  height: 253px;
  
  transition: all 0.2s ease;
  
  background: url("@/assets/newHead/sjsm.webp") no-repeat;
  background-size: 100% 100%;
  .ewm {
    margin-left: 18px;
    margin-top: -40px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.09);
    padding: 10px;
    .ewmQR {
      background: #fff;
      padding: 20px;
    }
  }
  }
.popzwkfXz {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 217px;
  height: 253px;
  position: absolute;
  z-index: 500;
  left: 100%;
  right: 0;
  top: -6px;
  transition: all 0.2s ease;
  visibility: hidden;
  opacity: 0;
  background: url("@/assets/newHead/sjsm.webp") no-repeat;
  background-size: 100% 100%;
  .ewm {
    margin-left: 18px;
    margin-top: -40px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.09);
    padding: 10px;
    .ewmQR {
      background: #fff;
      padding: 20px;
    }
  }
}
.popzwkfXzxxx:hover .popzwkfXz {
  visibility: visible;
  transition: all 0.2s ease;
  opacity: 1;
}
.itemShowKfList:hover .popzwkfItem {
  visibility: visible;
  transition: all 0.2s ease;
  opacity: 1;
}
.loginOutDialog {
  .outTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .outTitLeft {
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 1);
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 20px;
      .outTitImg {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .titRightClose {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .outLoginCont {
    color: rgba(200, 215, 248, 1);
    font-family: "PingFang SC";
    font-weight: 400;
    font-size: 16px;
    margin: 30px 0;
    text-align: left;
  }
  .outLogBtn {
    border-radius: 10px;
    background: rgba(24, 148, 255, 1);
    border: 0;
    width: 100%;
    height: 60px;
    color: rgba(255, 255, 255, 1);
    font-family: "PingFang SC";
    font-weight: 400;
    font-size: 20px;
  }
}
/deep/.el-dialog {
  border-radius: 10px !important;
  background: rgba(26, 44, 56, 1);
  width: 800px !important;
  padding: 20px !important;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__header {
  display: none;
}

.setHeadPop {
  /deep/.el-dialog {
    display: flex;
    width: 700px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    border-radius: 10px;
    background: radial-gradient(
      133.65% 137.5% at -19.87% -5.42%,
      #02568e 6%,
      #13293b 61.18%,
      #152b3f 100%
    );

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      width: 100%;
      padding: 20px;
    }
  }

  .diaTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    .diaTit {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
    }

    img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
  .listOut {
    width: 100%;
    margin: 20px auto;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 68px);
    grid-gap: 33px;

    .imgList {
      width: 68px;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      position: relative;
      z-index: 4;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50px;
        position: relative;
        z-index: 2;
      }
      .tips {
        width: 100%;
        display: flex;
        place-content: center;
        place-items: center;
        gap: 20px;
        flex-shrink: 0;
        background: rgba(71, 116, 218, 0.8);
        position: absolute;
        height: 16px;
        bottom: 1px;
        color: rgba(255, 255, 255, 1);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        z-index: 3;
        border-radius: 0 0 200px 200px;
      }
    }
  }
  .headBtn {
    width: 100%;
    display: flex;
    place-content: center;
    place-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding: 8px 15px;
    border-radius: 8px;
    background: rgba(71, 116, 218, 1);
    border: 0;
    margin: 40px auto 0 auto;
    color: rgba(255, 255, 255, 1);
    font-family: "PingFang SC";
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    height: 56px;
  }
}
.leftHead::-webkit-scrollbar {
  border-radius: 2px;
  background: #101b1f;
  width: 4px;
  height: auto;
  flex-shrink: 0;
}
.leftHead::-webkit-scrollbar-thumb {
  width: 4px;
  height: auto;
  flex-shrink: 0;
  border-radius: 2px;
  background: #283e4c;
}



</style>
