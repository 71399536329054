<template>
  <el-dialog class="withDepostDialog" :visible="depositShow" @close="closeDialog('all')" :show-close="false" top="5vh">
    <div class="diaTitle">
      <div class="diaTit">充值</div>
      <img src="@/assets/postWith/titleColse.svg" @click="closeDialog('all')" />
    </div>
    <div class="contents">
      <div class="wbxqblm">
        <div class="title">
          <div class="titleLeft">
            <div class="blueVertical"></div>
            充值方式
          </div>
          <div class="titRight">
            {{ balance }}
            <img @click="init" class="reload" :class="{ rotate: getUserLoading || getDepostitLoading }"
              src="@/assets/postWith/sgya.svg" />
          </div>
        </div>
        <div class="wayDemo">
          <!-- :style="{ 'margin-right': (index + 1) % 4 !== 0 ? '8px' : 'auto' }" -->
          <div class="lists" v-for="(item, index) in list" :key="index" :class="{ active: actWay == index }"
            @click="selectCode(item, index)">
            <div class="topImg" v-show="item.preferential != 0">{{ '+' + item.preferential + '%' }}</div>
            <img :src="item.icon" />
            <div class="name">{{ item.name }}</div>
            <div class="customBom"></div>
          </div>
        </div>
      </div>

      <!-- <div class="wbxqblm">
        <div class="title">
          <div class="titleLeft">
            <div class="blueVertical"></div>
            支付方式
            
          </div>
        </div>
        <div class="twoOut" v-if="activeList.length > 0">
            <div
                class="neic"
                v-for="(item, index) in activeList"
                :key="index"
                :class="{ actNei: twoAct == item.id }"
                @click="twoChage(item)"
            >
                <div class="topImg" v-show="item.preferential != 0">
                {{ "+" + item.preferential + "%" }}
                </div>
                <div class="neiLeft">
                <img :src="item.icon" />
                </div>
                <div class="neiRight">
                <div class="twoName">{{ item.name }}</div>
                <div class="twoTips">
                    {{ list[actWay].name + item.minAmount }}以上充值
                </div>
                </div>
                <div class="customBom"></div>
            </div>
            </div>

        
      </div> -->


      <div class="wbxqblm">
        <div class="title">
          <div class="titleLeft">
            <div class="blueVertical"></div>
            充值金额
            <div class="messageGreen">
              <img src="@/assets/postWith/greenLamp.svg" />温馨提示：请按照选定金额支付，以便为您快速核实！
            </div>
          </div>
        </div>
        <div class="intPost">
          <div class="intLeft">
            <img class="dollarImg" src="@/assets/postWith/dollar.svg" />
            <el-input class="amoutInt" v-model="amount" type="number"
              :placeholder="`限额${minAmount} ~ ${maxAmount}元`"></el-input>

            <!-- <div class="colse" @click="colsePost"><img src="@/assets/postWith/colse.svg" /></div> -->
          </div>
        </div>

        <div class="wayDemo">
          <!--  :style="{ 'margin-right': (index + 1) % 7 !== 0 ? '10px' : 'auto' }" -->
          <div class="amountContent" v-for="(item, index) in amoList" :key="index + item"
            @click="activeAmo(item, index)" :class="{ activeAmo: amount == item }">
            {{ item }}
          </div>
        </div>
      </div>
      <!-- <div class="wbxqblm ">
        <div class="title">
          <div class="titleLeft">
            <div class="blueVertical"></div>
            存款活动

          </div>
        </div>
        <div class="twoOut" v-if="activeList.length > 0" style="justify-content: flex-start; gap:15px;">
          <div class="neic cknr" style="height: 52px;width: auto;margin-bottom:0;" v-for="(item, index) in hdList"
            :key="index" :class="{ actNei: hdAct == item.id }" @click="hdChage(item)">


            <div class="neiRight">
              <div class="twoName ckhd">{{ item.title }}<div @click.self="goActivity(item)" v-show="!item.isXq"
                  class="xqBtn">详情>
                </div>
              </div>

            </div>
            <div class="customBom"></div>
          </div>
        </div>


      </div> -->
      <!-- <div class="topImg" v-show="item.preferential != 0">
          {{ "+" + item.preferential + "%" }}
        </div> -->
      <div class="ckydwt">
        <div class="encounter">
          存款遇到问题？联系<img src="@/assets/login/service.svg" @click="getService()" /><span @click="getService()">在线客服</span>
        </div>
        <van-button :disabled="!amount > 0 ||
          subLoading ||
          amount < minAmount ||
          amount > maxAmount
          " class="btn" :loading="subLoading" @click="submit">立即存款</van-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { depositChannel, userInfo, paymentCreate, activityList } from "@/request/api";
import { mapState, mapActions } from 'vuex';
export default {
  components: {},
  name: "depositIndex",

  data() {
    return {
      actWay: 0,
      list: [],
      amount: "",
      amountActive: -1,
      amoList: [],
      subLoading: false,
      getUserLoading: false,
      balance: 0,
      getDepostitLoading: false,
      amountCode: "",
      minAmount: "",
      maxAmount: "",
      activeList: [],
      isSettingRealName: false,
      isReal: false,
      hdList: [{ id: 0, title: "不参加活动", isXq: true }],
      hdAct: 0,
      activityLoading: false,
    };
  },
  computed: {
    ...mapState({
      depositShow: (state) => state.popShow.depositShow,
    }),
  },
  mounted() {
    this.getDepositChannel();
    this.getUserInfo();
    // this.getActivityList();
  },
  methods: {
    ...mapActions(['setActivePop']),
    goActivity(item) {
      if (item.code == "SIGN") {

        this.$router.push("/activity");
      } else {
        var times = "";
        if (item.activityStartTime && !item.activityEndTime) {
          times = "长期";
        } else {
          times = `${this.formattedDates(
            item.activityStartTime
          )} 至 ${this.formattedDateEnds(item.activityEndTime)}`;
        }
        if (item.code == "zhizhen") {

          this.$router.push({
            path: "/perfectionActivity",
            query: { code: item.code, time: times, id: item.id },
          });
        } else if (item.code == "EBPayDeposit" || item.code == 'EBPayWithdraw' || item.code == 'revive') {

          this.$router.push({
            path: "/ebpayActivity",
            query: { code: item.code, time: times, id: item.id },
          });
        }
        else if (item.code == "xrlb3" || item.code == 'xrlb4') {

          this.$router.push({
            path: "/newActivity2",
            query: { code: item.code, time: times, id: item.id },
          });
        } else if (item.code == "DZP" || item.code == 'dzp') {

          this.$router.push({
            path: "/turntableActivity",
            query: { code: item.code, time: times, id: item.id },
          });
        }
        else
          if (item.code == "xrlb1" || item.code == "xrlb2") {

            this.$router.push({
              path: "/newActivity",
              query: { code: item.code, time: times, id: item.id },
            });
          }
          else if (item.code == 'mgGame') {

            window.open('https://mgr.basebit.net/#/static/exclusive-jackpot/zh-cn', "_blank");
          }
          else {

            this.$router.push({
              path: "/sportsActivity",
              query: { code: item.code, time: times, id: item.id },
            });
          }
      }
      this.closeDialog('all')
    },
    closeDialog(fieldName) {
      this.setActivePop(fieldName);
    },
    getService() {
      this.$store.commit("goService")
    },
    upLoginShow(newValue) {
      this.$emit("update:depositShow", newValue);
    },
    init() {
      this.getDepositChannel();
      this.getUserInfo();
      this.getActivityList();
      this.amountActive = -1;
      this.amount = "";
    },
    hdChage(item) {
      this.hdAct = item.id
    },
    getActivityList() {
      this.activityLoading = true;
      this.hdList = [{ id: 0, title: "不参加活动", isXq: true }]
      activityList().then((res) => {

        if (res.code == 0) {
          this.hdList.push(...res.data.records.filter((item) => { return item.tag.includes("存款") }))
        }
        this.activityLoading = false;
      });
    },
    twoChage(item) {
      this.twoAct = item.id;
      if (item.amountList.length > 0) {
        this.amoList = item.amountList.split(",");
      } else {
        this.amoList = [];
      }
      this.amountActive = -1;
      this.amount = '';
      this.amountCode = item.code;
      console.log(this.amountCode);
      this.minAmount = item.minAmount;
      this.maxAmount = item.maxAmount;
      this.channelSource = item.channelSource;
      this.preferential = item.preferential;
      this.isReal = item.isReal;
      item.rate ? (this.rate = item.rate) : (this.rate = "");
    },
    selectCode(item, index) {
      // this.actWay = item.id
      // this.amoList = item.amountList.split(',');
      // this.amountActive = -1;
      // this.amount = "";
      // this.amountCode = item.code;
      // this.minAmount = item.minAmount
      // this.maxAmount = item.maxAmount
      this.actWay = index;
      // if (item.channelList[0].amountList.length > 0) {
      //   this.amoList = item.channelList[0].amountList.split(",");
      // } else {
      //   this.amoList = [];
      // }
      if (item.amountList && item.amountList.length > 0) {
        this.amoList = item.amountList.split(",");
      } else {
        this.amoList = [];
      }
      this.hdAct = 0;
      this.amount = "";
      this.amountActive = -1;
      // this.amount = "";
      // this.amountCode = item.channelList[0].code;
      // this.minAmount = item.channelList[0].minAmount;
      // this.maxAmount = item.channelList[0].maxAmount;
      // this.channelSource = item.channelList[0].channelSource;
      // this.preferential = item.channelList[0].preferential;
      // this.isReal = item.channelList[0].isReal;

      // this.activeList = item.channelList;
      // this.twoAct = item.channelList[0].id;

      // item.channelList[0].rate
      //   ? (this.rate = item.channelList[0].rate)
      //   : (this.rate = "");
      this.minAmount = item.minAmount;
      this.maxAmount = item.maxAmount;
      this.preferential = item.preferential;
    },
    activeAmo(item, index) {
      this.amountActive = index;
      this.amount = item;
    },
    colsePost() {
      this.amountActive = -1;
      this.amount = "";
    },
    submit() {
      if (this.isReal) {
        if (!this.isSettingRealName) {
          this.$message.success("请先绑定姓名")
          this.$router.push("/setup");
          return;
        }
      }
      this.subLoading = true;
      var opt = {
        // amount: this.amount,
        // code: this.amountCode,
        // activity:this.hdAct,
        amount: this.amount,
        // code: this.amountCode,
        cid: this.list[this.actWay].id,
        activity: this.hdAct,
      };
      paymentCreate(opt).then((res) => {
        if (res.code == 0) {
          this.$message.success("提交成功");
          this.$store.commit("setLoadingShowTrue");
          if (this.list[this.actWay].name == 'USDT') {
            this.$store.commit("setusdData", res.data)
            this.setActivePop("depositContentShow");
          } else {
            window.location.href = res.data.url;
          }

          setTimeout(() => {
            this.$store.commit("setLoadingShowFalse");
          }, 5000);
        }
        this.subLoading = false;
      });
    },
    getUserInfo() {
      this.getUserLoading = true;
      userInfo().then((res) => {
        if (res.code == 0) {
          console.log(res);
          this.balance = res.data.balance.toFixed(2);
          this.isSettingRealName = res.data.isSettingRealName;
          //this.userData = res.data
        }
        this.getUserLoading = false;
      });
    },
    getDepositChannel() {
      this.getDepostitLoading = true;
      depositChannel().then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            // this.actWay = res.data[0].id
            // this.amountCode = res.data[0].code
            // this.amoList = res.data[0].amountList.split(',');
            // this.minAmount = res.data[0].minAmount
            // this.maxAmount = res.data[0].maxAmount
            this.list = res.data;
            this.actWay = 0;
            // this.activeList = res.data[0].channelList;
            // this.twoAct = res.data[0].channelList[0].id;
            // this.channelSource = res.data[0].channelList[0].channelSource;
            // this.amountCode = res.data[0].channelList[0].code;
            // this.preferential = res.data[0].channelList[0].preferential;
            // if (res.data[0].channelList[0].rate) {
            //   this.rate = res.data[0].channelList[0].rate;
            // }
            // this.isReal = res.data[0].channelList[0].isReal;
            // if (res.data[0].channelList[0].amountList.length > 0) {
            //   this.amoList = res.data[0].channelList[0].amountList.split(",");
            // } else {
            //   this.amoList = [];
            // }
            // this.amount = "";
            // this.minAmount = res.data[0].channelList[0].minAmount;
            // this.maxAmount = res.data[0].channelList[0].maxAmount;
            this.activeList = res.data;
            this.preferential = res.data[0].preferential;
            if (res.data[0].amountList.length > 0) {
              this.amoList = res.data[0].amountList.split(",");
            } else {
              this.amoList = [];
            }
            this.amount = '';
            this.minAmount = res.data[0].minAmount;
            this.maxAmount = res.data[0].maxAmount;
          }
        }
        this.getDepostitLoading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.withDepostDialog {
  /deep/.el-dialog {
    display: flex;
    width: 700px;

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    border-radius: 10px;
    background: radial-gradient(133.65% 137.5% at -19.87% -5.42%,
        #02568e 6%,
        #13293b 61.18%,
        #152b3f 100%);

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      width: 100%;
      padding: 20px;
    }
  }

  .diaTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    .diaTit {
      color: #fff;
      font-family: "PingFang SC";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
    }

    img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }
}

.contents {
  background-repeat: no-repeat;
  background-size: cover;

  .wbxqblm {
    width: 100%;
    display: flex;
    padding: 10px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    border-radius: 10px;
    border: 0.5px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.04);
    margin-bottom: 14px;
  }

  .title {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-content: center;
    justify-content: space-between;
    color: #fff;
    font-family: "PingFang SC";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 20px;

    .titleLeft {
      display: flex;
      align-items: center;

      .blueVertical {
        width: 4px;
        height: 32px;
        border-radius: 0px 8px 8px 0px;
        background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);
        margin-right: 10px;
      }

      .messageGreen {
        display: flex;
        padding: 5px 10px;
        align-items: center;
        gap: 5px;
        border-radius: 10px;
        background: rgba(44, 209, 88, 0.09);
        color: #2cd158;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        margin-left: 10px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .titRight {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-align: right;
      font-family: DIN;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;

      img {
        width: 16px;
        height: 16px;
        margin-left: 3px;
      }
    }
  }

  .wayDemo {
    display: flex;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    gap: 8px;

    .amountContent {
      cursor: pointer;
      width: 80px;
      height: 40px;
      flex-shrink: 0;
      border: 1px solid rgba(255, 255, 255, 0.09);
      color: #fff;
      text-align: center;
      font-family: "SF Pro Display";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border-radius: 10px;
      line-height: 40px;
      margin-bottom: 14px;
    }

    .activeAmo {
      border: #0385ff 1px solid;
      color: #0385ff;
    }

    .lists {
      width: 96px;
      height: 87px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.04);
      flex-shrink: 0;
      padding-top: 20px;
      position: relative;

      margin-bottom: 8px;

      .topImg {
        color: #fff;
        font-family: DIN;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        position: absolute;
        display: inline-flex;
        padding: 1px 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 7px 0px;
        background: linear-gradient(47deg, #ec8b32 -3.03%, #ec3232 84.85%);
        top: 0;
        left: 0;
      }

      img {
        width: 26px;
        height: 26px;
        margin: 0 auto;
      }

      .name {
        color: #fff;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-top: 5px;
      }

      .customBom {
        opacity: 0;
        position: absolute;
        right: -1px;
        bottom: -0.5px;
        background-image: url("../../assets/postWith/enters.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 31px;
        height: 26px;
      }
    }

    .active {
      background: #283e4c;
      border: 1px solid #0385ff;

      .customBom {
        opacity: 1 !important;
      }
    }
  }

  .intPost {
    height: 48px;
    display: flex;
    padding: 12px 12px 12px 48px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.04);
    background: rgba(255, 255, 255, 0.09);

    margin-bottom: 6px;

    .intLeft {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;

      .dollarImg {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        fill: #ff3c44;
        filter: drop-shadow(0px 8px 12px rgba(255, 60, 68, 0.4));
        position: absolute;
        left: -48px;
        top: 4px;
      }

      .amoutInt {
        /deep/.el-input__inner {
          padding-left: 0;
          background: transparent;
          border: 0;
          color: #fff;
        }

        /deep/.el-input__inner::-webkit-outer-spin-button,
        /deep/.el-input__inner::-webkit-inner-spin-button {
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
          -o-appearance: none !important;
          -ms-appearance: none !important;
          appearance: none !important;
          margin: 0;
        }

        input[type="number"] {
          -webkit-appearance: textfield;
          -moz-appearance: textfield;
          -o-appearance: textfield;
          -ms-appearance: textfield;
          appearance: textfield;
        }
      }

      .colse {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .intRight {
      color: rgba(255, 255, 255, 0.45);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  .ckydwt {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .encounter {
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: center;

      img {
        margin: 0 5px 0 10px;
        cursor: pointer;
      }

      span {
        cursor: pointer;
        user-select: none;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        background: linear-gradient(180deg, #fff -54.54%, #c4deff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .btn {
      display: flex;
      width: 280px;
      height: 46px;
      padding: 4px 12px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 0;
      background: linear-gradient(92deg, #0385ff 0%, #0359ff 100%);
      color: #fff;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 24px */
    }
  }
}

.reload {
  &.rotate {
    animation: spin 1s linear infinite;
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.twoOut {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto 0 auto;
  flex-wrap: wrap;
  gap: 0 6px;

  .neic {
    transition: all 0.5s ease;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.04);
    width: 201.3px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 10px;
    position: relative;
    min-height: 80px;

    .customBom {
      opacity: 0;
      position: absolute;
      right: -1px;
      bottom: -1px;
      background-image: url("../../assets/postWith/enters.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 31px;
      height: 26px;
    }

    .topImg {
      color: #fff;
      font-family: DIN;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      position: absolute;
      display: inline-flex;
      padding: 1px 4px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px 0px;
      background: linear-gradient(47deg, #ec8b32 -3.03%, #ec3232 84.85%);
      top: 0;
      left: 0;
    }

    .neiLeft {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .neiRight {
      .twoName {
        text-align: left;
        color: rgba(255, 255, 255, 0.65);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 14px;
      }

      .ckhd {
        display: flex;
        align-items: center;
        user-select: none;

        .xqBtn {
          color: #0385FF;
          margin-left: 10px;
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          cursor: pointer;
        }
      }

      .twoTips {
        margin-top: 2px;
        text-align: left;
        color: rgba(255, 255, 255, 0.65);
        font-family: "PingFang SC";
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .cknr {
    padding: 10px 20px;
  }

  .actNei {
    border: 1px solid rgba(71, 116, 218, 1);

    .customBom {
      opacity: 1 !important;
    }
  }
}
</style>
