<template>
  <div class="homeDemo" :style="{ height: isFold ? '38px' : 'auto' }">
    <!-- <van-swipe
        class="my-swipeTwo"
        v-if="name == '体育'"
        :autoplay="3000"
        :show-indicators="false"
        :vertical="true"
        :touchable="false"
      >
        <van-swipe-item>
          <img class="lazyImg" src="@/assets/home/tab/sportSmall0.webp" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="lazyImg" src="@/assets/home/tab/sportSmall1.webp" />
        </van-swipe-item>
      </van-swipe> -->
     
    <!-- <div class="titleOut">体育投注</div> -->
     <!-- @click="isFold = !isFold" -->
    <div class="fold"  ref="scrollTarget">
      <div class="foldLeft">
        <img :src="icon" />
        {{ name }}
      </div>

     
      

      <div class="foldRight">
        <!-- <img
          src="@/assets/home/arrow.svg"
          :style="{ transform: isFold ? 'rotate(180deg)' : 'rotate(0deg)' }"
        /> -->
      </div>
    </div>

    <!-- 体育 -->
    <div
      class="sportListOut "
      v-if="
        name == '体育' || name == '区块链' || name == '电竞' || name == '彩票'|| name == '真人' || name == '电子' || name == '棋牌'|| name == '捕鱼'
      "
    >
    <!-- :style="{
          backgroundImage: `url(${item.h5Image})`,
          backgroundSize: '100% 100%',
        }" -->
      <div
        class="widthDemo moact"
        v-for="(item, index) in list"
        :key="index"
        @click="goPage(item)"
        
        v-show="item.status != 2 && item.id != 10"
      >
      <img class="imgIn" :src="item.h5Image" />
        <div class="maintainDemo" v-show="item.status != 0">
          <img src="@/assets/home/tab/homeWhz.svg" />{{
            item.status | getStatus
          }}
        </div>
      </div>
    </div>

    <div class="smailOut" v-else :class="{ zerenOut: name == '真人' }">
      <div
        class="smailList moact"
        v-for="(item, index) in list"
        :key="index"
        @click="goPage(item)"
        :class="{ caicaic: name == '彩票', zeren: name == '真人' }"
        :style="{
          backgroundImage: `url(${name == '真人'?item.pcImage:item.h5Image})`,
          backgroundSize: '100% 100%',
        }"
        v-show="item.status != 2"
      >
        <!-- <div class="smailBj" :style="{'backgroundImage': `url(${item.image})` }"> -->
        <div class="smailMaintainDemo" v-show="item.status != 0">
          <img src="@/assets/home/tab/homeWhz.svg" />{{
            item.status | getStatus
          }}
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { pdgameLaunch, platformLogin } from "@/request/api";
export default {
  name: "sportsDemo",
  props: ["list", "name", "icon", "tabs"],
  data() {
    return {
      isFold: false,
    };
  },
  methods: {
    setFold() {
      this.isFold = false;
    },
   async goPage(item) {
      await this.$sleep(500);
      if (item.status != 0) {
        return;
      }
      
        if (localStorage.getItem("userYou")&&localStorage.getItem("userYou") == "tourists") {
          // this.$notify({
          //   message: "请登录后查看",
          //   duration: 2000,
          //   background: "#FF4337",
          // });
          this.$message.warning("请登录后查看");
          return;
        }
 
      if (item.launchCode) {
        this.$store.commit("setLoadingShowTrue");
        platformLogin({ code: item.launchCode, id: item.id }).then((res) => {
          if (res.code == 0) {
            this.$store.commit("setUrl", res.data.url);
            if (item.name == "VR彩票") {
              window.open(res.data.url, "_blank");
            } else {
              // this.$router.push({
              //   path: "/block",
              //   query: { link: "/", tabs: this.tabs, url: res.data.url },
              // });
              window.open(res.data.url, "_blank")
            }
            setTimeout(() => {
              this.$store.commit("setLoadingShowFalse");
            }, 5000);
          } else {
            this.$store.commit("setLoadingShowFalse");
          }
        });
      } else {
        if (this.name != '电子') {
        var regex = /we/i;
        if (!regex.test(item.name)) {
          console.log("no");
        } else {
          console.log("we");
          this.oldSports(item);

          return;
        }
      }
        this.$router.push({
          path: "/classIndex",
          query: {
            list: JSON.stringify(item),
            id: item.id,
            name: this.name,
            tabs: this.tabs,
          },
        });
      }
    },

    oldSports(item) {
      if (item.status != 0) {
        return;
      }
      var regex = /we/i;
      var opt = {};
      if (item.name == "SABA") {
        //saba单独走
        opt = {
          code: item.launchCode ? item.launchCode : item.code,
        };
      } else if (!regex.test(item.name)) {
        if (item.gameCount > 0) {
          this.$router.push({
            path: "/classIndex",
            query: {
              list: JSON.stringify(item),
              id: item.id,
              name: this.name,
              tabs: this.tabs,
            },
          });
          return;
        }
      } else {
        opt = {
          code: item.code,
        };
      }
      //到这里就是名字有pd的 或者数量只有一个的   还有一个saba
      this.$store.commit("setLoadingShowTrue");

      pdgameLaunch(opt).then((res) => {
        if (res.code == 0) {
          // window.location.href = res.data
          this.$store.commit("setUrl", res.data);
          // if(!regex.test(this.list[index].name)){
          //     this.$router.push({path:'/block'})
          // }else{
          //     this.$router.push({path:'/sports'})
          // }
          // this.$router.push({
          //   path: "/block",
          //   query: { link: "/", tabs: this.tabs, url: res.data },
          // });
          window.open(res.data, "_blank")
          setTimeout(() => {
            this.$store.commit("setLoadingShowFalse");
          }, 5000);
        } else {
          this.$store.commit("setLoadingShowFalse");
        }
      });
    },

    goPages(index) {
      if (this.list[index].status != 0) {
        return;
      }
      var regex = /we/i;
      if (!regex.test(this.list[index].name)) {
        if (this.list[index].gameCount > 0) {
          this.$router.push({
            path: "/classIndex",
            query: { list: JSON.stringify(this.list[index]) },
          });
          return;
        }
      }
      this.$store.commit("setLoadingShowTrue");
      var opt = {
        code: this.list[index].code,
      };
      pdgameLaunch(opt).then((res) => {
        if (res.code == 0) {
          // window.location.href = res.data
          this.$store.commit("setUrl", res.data);
          // if(!regex.test(this.list[index].name)){
          //     this.$router.push({path:'/block'})
          // }else{
          //     this.$router.push({path:'/sports'})
          // }
          this.$router.push({ path: "/block" });
          setTimeout(() => {
            this.$store.commit("setLoadingShowFalse");
          }, 5000);
        } else {
          this.$store.commit("setLoadingShowFalse");
        }
      });
    },
  },
  filters: {
    getStatus(status) {
      switch (status) {
        case 0:
          return "";
        case 1:
          return "维护中";
        case 2:
          return "已停用";
        default:
          return "";
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.homeDemo {
  transition: all 0.5s ease;
  overflow: hidden;
  position: relative;
  // margin-bottom: 8px;
  .fold {
    width: 92%;
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 38px;
    transition: all 0.5s ease;
    position: relative;
   
    .foldLeft {
      display: flex;
      align-items: center;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }

    .foldRight {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        transition: all 0.5s ease;
      }
    }
  }

  .titleOut {
    color: #fff;
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
    text-align: left;
    width: 89.3333%;
    margin: 20px auto 0 auto;
  }

 
  .sportListOut{
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 361px);
    grid-gap: auto;
    cursor: pointer;
  }
  .widthDemo {
    width: 361px;
    // height: 150px;
    // border-radius: 12px;
    overflow: hidden;
    margin-bottom: 11px;
    position: relative;

    // background: radial-gradient(
    //   114.24% 141.42% at 0% 0%,
    //   rgba(155, 155, 186, 0.16) 31.69%,
    //   rgba(155, 155, 186, 0.04) 100%
    // );
    .imgIn {
      width: 100%;
      vertical-align: middle;
    }
    .maintainDemo {
      width: 138.5px;
      height: 100%;
      position: absolute;
      background-image: url("@/assets/home/tab/home-mark.svg");
      background-size: cover;
      border-radius: 0 0 16px 0;
      right: -0.5px;
      bottom: 0;
      margin: auto;
      z-index: 3;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      color: #fff;
      padding: 0 10px 12px 0;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
    }

    .tips {
      color: rgba(255, 255, 255, 0.65);
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      text-align: left;
    }

    .title {
      margin-top: 14px;
      color: #fff;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      /* 24px */
      text-align: left;
      display: flex;
      align-items: flex-end;

      .titLeft {
        color: #fff;
        font-family: "FONTSPRING DEMO - Integral CF Demi Bold";
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 95%;
        /* 30px */
      }

      .titRight {
        display: inline-flex;
        padding: 0 12px;
        height: 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #fff;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        border-radius: 16px;
        background: rgba(255, 255, 255, 0.15);
        margin-left: 8px;
      }
    }

    .titTwo {
      margin-top: 10px;
      /* 18px */
      text-align: left;

      color: #fff;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }

    .inbtn {
      display: flex;
      width: 88px;
      height: 32px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      flex-shrink: 0;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.15);
      color: var(---100, #fff);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-top: 12px;
    }
  }

  .smailOut {
    display: grid;
    cursor: pointer;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 172px);
    grid-gap: 15px;
    width: 100%;
    margin: 0px auto 0 auto;
    flex-wrap: wrap;
    .smailList {
      width: 172px;
      height: 211px;
      border-radius: 12px;

      background: radial-gradient(
        114.24% 141.42% at 0% 0%,
        rgba(155, 155, 186, 0.16) 31.69%,
        rgba(155, 155, 186, 0.04) 100%
      );
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      .smailBj {
        width: 100%;
        height: 100%;
        border-radius: 12px;
      }
      .smailMaintainDemo {
        width: 100%;
        height: 70px;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(16, 27, 31, 0) 0%,
          rgba(16, 27, 31, 0.75) 66%
        );
        background-size: cover;
        border-radius: 0 0 12px 12px;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 3;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        color: #fff;
        padding: 0 10px 12px 0;
        font-family: "PingFang SC";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;

        img {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
      }
    }
    .caicaic {
      width: 115px !important;
      height: 177px !important;
    }

    .zeren {
      width: 200px !important;
      height: 240px !important;
    }
  }
  .zerenOut {
    grid-template-columns: repeat(auto-fill, 200px) !important;
    grid-gap: 25px;
  }
}

.my-swipeTwo {
  width: 138px;
  height: 28px;
  position: absolute;
  top: 6px;

  left: 0;
  right: 0;
  margin: auto;
  z-index: 4;
  // border-radius: 20px;
  .lazyImg {
    width: 138px;
    height: 28px;
  }
}
.lazyImg {
    width: 138px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2px;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 4;
    img{
      width: 100%;
      height: 100%;

    }
  }
</style>
